<template>
  <v-container fluid>
    <v-card raised v-if="showScreen">
      <v-flex>
        <transporterlist
          :loader="loading"
          v-on:on-load="startLoad"
          v-on:off-load="stopLoad"
        />
      </v-flex>
    </v-card>
  </v-container>
</template>
<script>
import _ from "lodash";
import Swal from "sweetalert2";
import transporterlist from "./transporterlist";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import Loading from "vue-loading-overlay";

export default {
  mixins: [checkPermissionsMixin],
  components: {
    Loading,
    transporterlist,
  },
  created() {
    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });
  },
  data() {
    return {
      items: {
        list: [],
      },
      showScreen: true,
      active: "0",
      loading: true,
      search: "",

      payload: {
        skip: "0",
        limit: "15",
      },
    };
  },
  methods: {
    startLoad() {
      this.loading = true;
    },
    stopLoad() {
      this.loading = false;
    },

    load(event) {
      this.loading = false;
    },
  },
};
</script>

<style></style>
