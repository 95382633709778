<template>
  <v-layout class="pt-3 pr-2">
    <v-flex xs3 class="div_hide_mobile"></v-flex>
    <v-flex xs6 class="pagination_wrapper" style="text-align: center">
      <v-pagination
        v-model="pageNo"
        :length="this.totalPages"
        @input="next"
        :disabled="loading"
        :total-visible="5"
        circle
      ></v-pagination>
    </v-flex>
    <v-flex xs3 class="div_hide_mobile"></v-flex>

    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
  </v-layout>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
export default {
  created() {
    this.nextUrl = this.url;
    this.next();
  },
  components: {
    ErrorBox,
  },
  watch: {
    payload: {
      handler(val) {
        this.pageNo = 1;
        this.obj = val;
        this.next();
      },
      deep: true,
    },

    componentKey: {
      handler(val) {
        this.next();
      },
      deep: true,
    },
    url: {
      handler(val) {
        this.pageNo = 1;
        this.next();
      },
      deep: true,
    },
  },
  props: {
    url: String,
    callback: Function,
    action: String,
    totalPages: Number,
    loading: Boolean,
    componentKey: Number,
    type: Number,
    payload: Object,
  },
  // props: [
  //   "url",
  //   "callback",
  //   "action",
  //   "totalPages",
  //   "loading",
  //   "componentKey",
  //   "type",
  //   "payload",
  // ],
  data() {
    return {
      theme: "#f47824",
      pageNo: 1,
      obj: {},
      pagination: {},
      prevUrl: null,
      prevLoading: false,
      nextUrl: null,
      nextLoading: false,
      x: {
        error: null,
      },
    };
  },
  methods: {
    next() {
      if (this.type == 0) {
        if (this.url) {
          if (this.action == "job")
            localStorage.setItem("paginationUrlJob", this.url);

          if (this.action == "procure")
            localStorage.setItem("paginationUrlProcure", this.url);
          if (this.action == "user")
            localStorage.setItem("paginationUrlUser", this.url);
          if (this.action == "advance")
            localStorage.setItem("paginationUrlAdvance", this.url);
          if (this.action == "assignment")
            localStorage.setItem("paginationUrlAssignment", this.url);
          this.$emit("pagination-load", true);
          this.nextLoading = true;
          let url = this.constants.apiUrl;

          if (
            this.action == "job" ||
            this.action == "user" ||
            this.action == "procure" ||
            this.action == "bookings" ||
            this.action == "sales" ||
            this.action == "assignment" ||
            this.action == "advance" ||
            this.action == "customerList" ||
            this.action == "salesReport" ||
            this.action == "advanceListsub"||
            this.action == "purchaseReport" ||
            this.action == "advancePaymentReport" ||
            this.action == "additionalChargeList" ||
            this.action == "advancePaymentReport2"
          ) {
            this.$emit("pagination-load", true);
            url = this.constants.apiUrl;
            delete this.axios.defaults.headers.common["token"];
            let token = localStorage.getItem(StorageKeys.SessionKey);
            this.axios.defaults.headers.common[
              "authorization"
            ] = `bearer ${token}`;
          }

          this.axios.get(url + this.url + "&pageNo=" + this.pageNo).then(
            (response) => {
              const { pageNo = this.pageNo, data } = response.data;
              this.pageNo = pageNo;
              if(this.action == "additionalChargeList"){
                this.callback(data,this.pageNo );
              }else{
                this.callback(data);
              }
            

              this.nextLoading = false;
              this.$emit("pagination-load", false);
            },
            (error) => {
              this.x.error = "Failed to Fetch Data";
              this.$emit("pagination-load", false);
            }
          );
        }
      } else {
        if (this.url) {
          this.$emit("pagination-load", true);
          this.nextLoading = true;
          let url = this.constants.apiUrl;
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          this.pageNo ? (this.obj.pageNo = this.pageNo.toString()) : " ";
          let mainPayload = {};
          mainPayload = { ...this.payload, ...this.obj };
          this.axios.defaults.headers.common[
            "authorization"
          ] = `bearer ${token}`;
          this.axios.post(url + this.url, mainPayload).then(
            (response) => {
              const { pageNo = this.pageNo, data } = response.data;
              this.pageNo = pageNo;
              this.callback(data);
              this.nextLoading = false;
              this.$emit("pagination-load", false);
            },
            (error) => {
              this.x.error = "Failed to Fetch Data";
              this.$emit("pagination-load", false);
            }
          );
        }
      }
    },
  },
};
</script>

<style></style>
